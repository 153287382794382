<template>
  <div class="wrapper" ref="scroll" :style="{ height: `${state.height}px` }">
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'VotingScroll',
}
</script>
<script setup lang="ts">
import BScroll from '@better-scroll/core'
import PullDown from '@better-scroll/pull-down'
import PullUp from '@better-scroll/pull-up'
import ObserveImage from '@better-scroll/observe-image'
import ObserveDOM from '@better-scroll/observe-dom'

BScroll.use(PullDown)
BScroll.use(PullUp)
BScroll.use(ObserveImage)
BScroll.use(ObserveDOM)

// 1. probeType 为 0，在任何时候都不派发 scroll 事件，
// 2. probeType 为 1，仅仅当手指按在滚动区域上，每隔 momentumLimitTime 毫秒派发一次 scroll 事件，
// 3. probeType 为 2，仅仅当手指按在滚动区域上，一直派发 scroll 事件，
// 4. probeType 为 3，任何时候都派发 scroll 事件，包括调用 scrollTo 或者触发 momentum 滚动动画
const props = withDefaults(
  defineProps<{
    height: number
    probeType?: number
    pullUp?: boolean // 上拉加载
    pullDown?: boolean // 下拉刷新
  }>(),
  {
    probeType: 1,
    pullUp: true,
    pullDown: false,
  }
)

const emits = defineEmits(['pullUp', 'pullDown', 'scrollStart', 'scroll', 'scrollEnd'])

const scroll = ref()

let bs: any

const state = reactive<{
  height: number
  isPullUp: boolean
  isPullDown: boolean
}>({
  height: props.height,
  isPullUp: false,
  isPullDown: false,
})

watch(
  () => props.height,
  newValue => {
    state.height = newValue
  }
)

onMounted(() => {
  initScroll()
})

onBeforeUnmount(() => {
  if (bs) {
    bs.destroy()
  }
})

const initScroll = () => {
  bs = new BScroll(scroll.value, {
    click: true,
    probeType: props.probeType,
    scrollX: false,
    scrollY: true,
    useTransition: true,
    pullUpLoad: true,
    pullDownRefresh: true,
    observeImage: true,
    observeDOM: true,
    resizePolling: 30,
  })

  if (props.pullUp) {
    // 动态开启上拉加载
    bs.openPullUp()
  } else {
    bs.closePullUp()
  }

  if (props.pullDown) {
    // 动态开启下拉刷新
    bs.openPullDown({
      threshold: 10,
    })
  }

  // 上拉加载
  bs.on('pullingUp', async () => {
    state.isPullUp = true

    await emits('pullUp')

    bs.finishPullUp()
    state.isPullUp = false
  })

  // 下拉刷新
  bs.on('pullingDown', async () => {
    state.isPullDown = true

    await emits('pullDown')

    bs.finishPullDown()
    state.isPullDown = false

    setTimeout(() => {
      nextTick(() => {
        bs.refresh()
      })
    }, 20)
  })

  // 开始滚动
  bs.on('scrollStart', () => {
    emits('scrollStart')
  })

  // 滚动
  bs.on('scroll', (position: any) => {
    emits('scroll', position)
  })

  // 滚动结束
  bs.on('scrollEnd', () => {
    emits('scrollEnd')
  })
}

const refresh = () => bs && bs.refresh()
const scrollTo = (scrollY: number) => {
  bs && bs.scrollTo(0, scrollY, 0)
}

defineExpose({
  refreshScroll: refresh,
  scrollTo: (scrollY: number) => scrollTo(scrollY),
})
</script>

<style lang="less" scoped>
.wrapper {
  overflow: hidden;
  position: relative;
}
</style>
