<template>
  <svg aria-hidden="true" :class="props.className" class="svg_icon">
    <use :xlink:href="symbolId" :fill="props.color" />
  </svg>
</template>

<script lang="ts">
export default {
  name: 'SvgIcon',
}
</script>
<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    name?: string
    prefix?: string | undefined
    color?: string | undefined
    className?: string | undefined
  }>(),
  {
    prefix: 'icon',
    color: '#1989fa',
  }
)

const symbolId = computed(() => `#${props.prefix}-${props.name}`)
</script>
<style lang="less" scoped>
.svg_icon {
  fill: currentColor;
}
</style>
