<template>
  <div class="flex-column flex-center">
    <svg-icon name="no_data" style="width: 88px; height: 88px" />
    <span class="font-size-m text-gray-color">{{ title }}</span>
  </div>
</template>

<script lang="ts">
export default {
  name: 'VotingNoData',
}
</script>
<script setup lang="ts">
const props = withDefaults(defineProps<{ title?: string }>(), {
  title: '暂无数据',
})
</script>

<style lang="scss" scoped></style>
