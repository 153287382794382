import { createApp } from 'vue'
import App from './App.vue'
// 路由注入
import { injectionRouter, router } from '@/router'
// 全局组件注入
import useComponent from '@/plugins/useComponent'
// pinia引入
import pinia from '@/store'
// 移动端适配方案
import 'amfe-flexible'
// svg
import 'virtual:svg-icons-register'

// 创建Vue对象
const app = createApp(App)

// 注入路由
injectionRouter(app)
// 注入pinia
app.use(pinia)
// 注入组件
app.use(useComponent)

// 路由准备好，渲染app
router.isReady().then(() => {
  app.mount('#app')
})
