const getAppId = () => {
  return import.meta.env.VITE_WECHAT_APPID
}

// 微信授权
const wxAuth = () => {
  const url =
    process.env.NODE_ENV === 'local' ? 'http://localhost:3000/' : `${import.meta.env.VITE_BASE_API}/static/mobile/`
  // const url = location.href
  const redirectUri = `${import.meta.env.VITE_WX_AUTH_URL}/view/gdl/index.html?url=${encodeURIComponent(url)}`
  var oauth_url =
    'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
    getAppId() +
    '&redirect_uri=' +
    encodeURIComponent(redirectUri) +
    '&response_type=code&scope=snsapi_userinfo&state=OAI&component_appid=' +
    import.meta.env.VITE_COMPONENT_APPID +
    '#wechat_redirect'
  location.href = oauth_url
}

export default {
  getAppId,
  wxAuth,
}
