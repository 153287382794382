import { RouteRecordRaw } from 'vue-router'

const routers: RouteRecordRaw[] = [
  // 刷新页面一律回主页面
  {
    path: '/*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home.vue'),
    meta: {
      title: '实例不凡・大放光彩优秀病例票选',
    },
  },
  {
    path: '/vote',
    name: 'vote',
    component: () => import('@/views/vote.vue'),
    meta: {
      title: '投票页',
    },
  },
  {
    path: '/follow',
    name: 'follow',
    component: () => import('@/views/follow.vue'),
    meta: {
      title: '关注页面',
    },
  },
]
export default routers
