<script setup lang="ts">
import { GlobalStore } from '@/store'

// 全局Store
const useGlobalStore = GlobalStore()
// 不缓存路由
const excludeRoute = Object.freeze('home,vote')
</script>

<template>
  <van-config-provider class="main-provider" :theme-vars="useGlobalStore.themeConfig">
    <router-view v-slot="{ Component }">
      <keep-alive :exclude="excludeRoute">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </van-config-provider>
</template>

<style scoped lang="less">
.main-provider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
