import { App } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { Common, WxUtil } from '@/utils'
import { inforecord } from '@/api/user'
import pinia, { GlobalStore } from '@/store'
import routers from './routers'

export const router = createRouter({
  history: createWebHashHistory(process.env.NODE_ENV === 'local' ? '/' : '/static/mobile/'),
  // history: createWebHistory(),
  routes: routers,
})

// 路由守卫
router.beforeEach(async (to, form) => {
  // 如果登陆过了以后就不用登陆验证了
  const useGloblStore = GlobalStore(pinia)
  // 微信授权
  const openid = useGloblStore.getOpenid()
  const urlOpenid = Common.getUrlParams('openid')
  if (process.env.NODE_ENV !== 'local') {
    // 都不存在的情况下，进行微信验证
    if (!urlOpenid && !openid) {
      WxUtil.wxAuth()
    }

    // 都存在的情况下，如果不想等进行验证
    if (urlOpenid && openid) {
      if (urlOpenid !== openid) {
        WxUtil.wxAuth()
      }
    }
  }

  if (!openid && to.name !== 'follow') {
    const currentOpenId = Common.getUrlParams('openid')
    const nickname = Common.getUrlParams('nickname')
    if (currentOpenId) {
      const res: any = await inforecord(currentOpenId, nickname as string)
      if (res.ret === 4105) {
        return '/follow'
      }

      if (res.ret === 0) {
        useGloblStore.setOpenid(currentOpenId)
      }
    }
  }

  document.title = to.query && to.query.title ? (to.query.title as string) : (to.meta.title as string)
})

// 注入
export const injectionRouter = (app: App<Element>) => {
  app.use(router)
}
