import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Dialog, Toast } from 'vant'

class Https {
  private axiosServer: AxiosInstance
  private timeout = 1000000
  private static _instance: Https
  private loading: any
  // error list
  private errorList: any = {
    404: '请求地址错误',
    403: '令牌过期请重新授权验证!',
    400: '请求参数错误',
    406: '客户端参数错误,请检查',
    500: '后台服务错误',
    'Network Error': '网络异常,请检查网络!',
  }

  // todo 不需要loading白名单
  private loadList = ['/api/caselistview']

  constructor() {
    const apiUrl =
      process.env.NODE_ENV === 'dev' || process.env.NODE_ENV === 'local' ? '/' : import.meta.env.VITE_BASE_API
    this.axiosServer = axios.create({
      baseURL: apiUrl,
      timeout: this.timeout,
      validateStatus: function (status) {
        return status >= 200 && status <= 302 // default
      },
      maxRedirects: 3,
    })
    this.requestInterceptors()
    this.responseInterceptors()
  }

  private requestInterceptors() {
    this.axiosServer.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const requestUrl: string = config.url!

        // 不在loadList白名单中的请求显示loading
        if (!this.loadList.some(item => ~requestUrl.indexOf(item))) {
          // loading
          this.loading = Toast({
            type: 'loading',
            duration: 0,
            forbidClick: true,
          })
        }
        return config
      },
      (error: any) => {
        return Promise.reject(error)
      }
    )
  }

  private responseInterceptors() {
    this.axiosServer.interceptors.response.use(
      (result: AxiosResponse) => {
        console.log('result', result)
        if (this.loading) {
          this.loading.clear()
        }
        if (result.data.error) {
          return Promise.reject(result.data.error.errorInfo)
        }
        return result.data
      },
      (error: any) => {
        if (this.loading) {
          this.loading.clear()
        }
        const json: any = JSON.parse(JSON.stringify(error))
        if (json.status === 403) {
          Dialog.alert({
            message: this.errorList[json.status],
            confirmButtonColor: '#1B69FB',
            theme: 'round-button',
          })
        } else if (json.message === 'Network Error') {
          Dialog.alert({
            message: this.errorList[json.message],
            confirmButtonColor: '#1B69FB',
            theme: 'round-button',
          })
        } else {
        }
        return Promise.reject()
      }
    )
  }

  public static getInstance() {
    // 单例模式
    if (!this._instance) {
      this._instance = new Https()
    }
    return this._instance
  }

  public post(url: string, data?: any) {
    return this.axiosServer.post(url, data, {
      headers: { 'content-type': 'multipart/form-data' },
    })
  }

  public get(url: string, params?: any) {
    return this.axiosServer.get(url, { params })
  }

  public put(url: string, data?: any) {
    return this.axiosServer.put(url, data)
  }

  public delete(url: string) {
    return this.axiosServer.delete(url)
  }
}

export default Https.getInstance()
