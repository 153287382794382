import { ImagePreview } from 'vant'

class Common {
  // 获取url参数
  public getUrlParams(code: String) {
    if (window.location.search) {
      let searchParams = window.location.search.substring(1)
      let arrays: string[] = []
      if (~searchParams.indexOf('&')) {
        arrays = searchParams.split('&')
      } else {
        arrays.push(searchParams)
      }
      for (let i = 0; i < arrays.length; i++) {
        let params = arrays[i].split('=')
        if (params[0] === code) {
          return params[1]
        }
      }
    }
    return false
  }

  // 预览图片
  public previewImage({ images, startPosition = 0 }: { images: Array<string>; startPosition?: number | undefined }) {
    ImagePreview({
      images,
      startPosition,
      closeable: false,
    })
  }

  // 判断是否为空
  public isEmpty(value: string | number | undefined | null) {
    return value === undefined || value === null || value == ''
  }
}

export default new Common()
