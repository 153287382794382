import { defineStore, createPinia } from 'pinia'
import { GlobalState } from './interface'
import piniaPersistConfig from '@/configs/piniaPersist'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

// defineStore 调用后返回一个函数，调用该函数获得 Store 实体
export const GlobalStore = defineStore({
  // id: 必须的，在所有 Store 中唯一
  id: 'GlobalState',
  // state: 返回对象的函数
  state: (): GlobalState => ({
    openid: null,
    // themeConfig
    themeConfig: {
      primaryColor: '#836bb4',
      tabsDefaultColor: '#836bb4',
      borderRadiusSm: '6px',
      backgroundColor: '#2f284b',
    },
  }),
  getters: {},
  actions: {
    setOpenid(openid: string | null) {
      this.openid = openid
    },
    getOpenid() {
      return this.openid
    },
  },
  persist: piniaPersistConfig('VotingGlobalState'),
})

// piniaPersist(持久化)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

export default pinia
