<template>
  <div class="voting-container" id="voting-container" :style="{ height: state.height }">
    <div class="voting-header"></div>
    <div class="voting-center">
      <div class="voting-center-container">
        <slot></slot>
      </div>
    </div>
    <div class="safe-area"></div>
  </div>
</template>

<script lang="ts">
// 通用背景墙
export default {
  name: 'votingWall',
}
</script>
<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    title?: string
    isPadding?: boolean
  }>(),
  {
    isPadding: true,
  }
)

const state = reactive<{ height: string }>({
  height: '0px',
})

onBeforeMount(() => {
  setTimeout(() => {
    const safeArea = document.querySelector('.safe-area')
    if (safeArea) {
      // 延迟加载，解决ios底部出现的回退栏s
      state.height = `${document.body.clientHeight - 150 - safeArea.clientHeight}px`
    }
  }, 20)
})
</script>

<style lang="less">
.voting-container {
  background-image: url('https://qngdl.hrihealth.cn/%E6%B4%BB%E5%8A%A8%E6%97%B6%E9%97%B4%E6%9B%B4%E6%96%B02022.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: 100% auto;
  position: relative;
  background-color: @background-color;
  height: 100%;

  .voting-header {
    height: 130px;
  }

  .voting-center {
    height: 100%;
    background-color: @background-color;
    z-index: 2;

    &-container {
      .voting-scroll;
      height: 100%;
      width: 100%;
      background-color: @background-color;
    }

    &-container::-webkit-scrollbar {
      display: none;
    }
  }
  .safe-area {
    height: env(safe-area-inset-bottom);
  }
}
</style>
