import { Notify } from 'vant'
import { VotingWall, VotingScroll, VotingNoData } from '@/components'
import SvgIcon from '@/components/SvgIcon.vue'

// 画面用控件
const components: any[] = [SvgIcon, VotingWall, VotingScroll, VotingNoData]

export default {
  install: (app: any) => {
    app.use(Notify)

    components.forEach(item => {
      app.component(item.name, item)
    })
  },
}
